export const deleteWarehouseProductsCategories = async (category_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-product-categories/${category_id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
