import { Link } from "react-router-dom";
import React, { Component } from "react";

export default class WorkerMenu extends Component {
  render() {
    return (
      <>
        <li>
          <Link to="/dashboard" className="waves-effect">
            <i className="ri-dashboard-line" />
            <span className="ml-1">{this.props.t("Panel Główny")}</span>
          </Link>
        </li>

        <li>
          <Link to="/services" className="waves-effect">
            <i className="ri-archive-line" />
            <span className="ml-1">{this.props.t("Usługi")}</span>
          </Link>
        </li>

        <li>
          <Link to="/calendar" className=" waves-effect">
            <i className="ri-calendar-2-line" />
            <span className="ml-1">{this.props.t("Kalendarz")}</span>
          </Link>
        </li>

        <li>
          <Link to="/visits" className=" waves-effect">
            <i className="ri-checkbox-multiple-fill" />
            <span className="ml-1">{this.props.t("Wizyty")}</span>
          </Link>
        </li>

        <li>
          <Link to="/kontakty" className=" waves-effect">
            <i className="ri-contacts-book-line" />
            <span className="ml-1">{this.props.t("Kontakty")}</span>
          </Link>
        </li>

        <li>
          <Link to="/statystyki" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Statystyki")}</span>
          </Link>
        </li>

        <li>
          <Link to="/employees" className="waves-effect">
            <i className="ri-team-line" />
            <span className="ml-1">{this.props.t("Pracownicy")}</span>
          </Link>
        </li>

        <li>
          <Link to="/salons" className="waves-effect">
            <i className="ri-store-line" />
            <span className="ml-1">{this.props.t("Salony")}</span>
          </Link>
        </li>

        <li>
          <Link to="/profile-settings" className="waves-effect">
            <i className="ri-settings-3-line" />
            <span className="ml-1">{this.props.t("Ustawienia")}</span>
          </Link>
        </li>

        <li className="menu-title">{this.props.t("Zarządzanie Kontem")}</li>

        <li>
          <Link to="/magazyn" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Magazyn (Stary)")}</span>
          </Link>
        </li>
        <li>
          <Link to="/magazyny" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Magazyny")}</span>
          </Link>
        </li>
        <li>
          <Link to="/produkty" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Produkty")}</span>
          </Link>
        </li>
        <li>
          <Link to="/stany-magazynowe" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Stany magazynowe")}</span>
          </Link>
        </li>
        <li>
          <Link to="/panel-zarzadzania-salonem" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Zarządzanie systemem")}</span>
          </Link>
        </li>

        <li>
          <Link to="/pomoc" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Pomoc")}</span>
          </Link>
        </li>
        <li>
          <Link to="/app-store" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("AppStore")}</span>
          </Link>
        </li>
        <li>
          <Link to="/rejestry" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Rejestry")}</span>
          </Link>
        </li>
        <li>
          <Link to="/seo" className=" waves-effect">
            <i className="ri-bar-chart-line" />
            <span className="ml-1">{this.props.t("Moduł SEO")}</span>
          </Link>
        </li>
      </>
    );
  }
}
