import React, { Component, componentDidMount } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import "./modal.scss";
import Categories from "../../components/Categories/Categories";
import * as getServices from "../../Api/Services/getServices";
import * as postServices from "../../Api/Services/postServices";
import * as patchServices from "../../Api/Services/patchServices";
import * as deleteServices from "../../Api/Services/deleteServices";
import * as getTaxes from "../../Api/Taxes/getTaxes";
import * as getServicesCategories from "../../Api/Services/Categories/getServiceCategories";
import translations from "../../helpers/translations/pl";
import ToastNotifications from "../../components/ToastNotification/ToastNotification";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: localStorage.getItem("salon_name"),
      body: [],
      modal_standard: false,
      modal_addCustomer: false,
      defaultInputValue: [],
      editedInputValue: [],
      checkValid: false,
      // SERVICES DATA
      services: [],
      tax_options: [],
      service_categories: [],
      priceRegex: /^\d+(\.\d{0,2}|,\d{0,2})?$/,
      // SELECT OPTIONS
      selectedTaxGroup: null,
      optionTaxGroup: [
        {
          options: [
            {
              value: 1,
              label: "23%",
            },
          ],
        },
      ],
      selectedCategoryGroup: null,
      selectedCategory: {
        value: 0,
        label: "Brak",
      },
      optionCategoryGroup: [
        {
          options: [
            {
              value: 0,
              label: "Brak",
            },
          ],
        },
      ],
      isActiveServices: true,
      inactiveServices: [],
      selectedCategoryServices: [],
      serviceCategoryId: 0,
      totalNetPrice: 0,
      totalGrossPrice: 0,
      totalTax: 0,
    };

    this.tog_standard = this.tog_standard.bind(this);
    this.togAddService = this.togAddService.bind(this);
    this.editService = this.editService.bind(this);
    this.addService = this.addService.bind(this);
    // SELECT
    this.handleSelectTaxGroup = this.handleSelectTaxGroup.bind(this);
    this.handleSelectCategory = this.handleSelectCategory.bind(this);
    this.handleSelectCategoryGroup = this.handleSelectCategoryGroup.bind(this);
  }

  handlePrices = (e, type, tax = null) => {
    const {
      selectedTaxGroup,
      totalNetPrice,
      optionTaxGroup,
      defaultInputValue,
    } = this.state;
    const taxValue = () => {
      if (type === "tax" && tax) {
        return parseFloat(tax);
      }

      if (selectedTaxGroup) {
        return parseFloat(selectedTaxGroup.label);
      }

      if (defaultInputValue?.tax?.value) {
        return defaultInputValue.tax.value;
      }

      return parseFloat(optionTaxGroup[0].options[0].label);
    };

    const price = type === "tax" ? totalNetPrice : Number(e.target.value);
    const netPrice = (price * 100) / (100 + taxValue());
    const grossPrice = (price * (100 + taxValue())) / 100;

    if (type === "gross") {
      this.setState({
        totalNetPrice: netPrice.toFixed(2),
        totalGrossPrice: price,
      });
    } else {
      this.setState({
        totalNetPrice: price,
        totalGrossPrice: grossPrice.toFixed(2),
      });
    }
  };

  //Select
  handleSelectTaxGroup = (selectedTaxGroup) => {
    this.setState({ selectedTaxGroup: selectedTaxGroup });
    this.handlePrices(null, "tax", selectedTaxGroup.label);
  };

  handleSelectCategoryGroup = (selectedCategoryGroup) => {
    this.setState({
      selectedCategoryGroup,
      serviceCategoryId: selectedCategoryGroup.value,
    });
  };

  handleSelectCategory = (selectedCategory) => {
    getServices
      .getCategoryServices(this.state.salon_name, selectedCategory?.value || 0)
      .then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            let apiData = data.map((service) => {
              return {
                id: service.id,
                title: service.title,
                service_time_min: service.service_time_min,
                service_time_max:
                  service.service_time_max !== undefined
                    ? service.service_time_max
                    : "",
                price: service.price !== undefined ? service.price : "",
                tax: service.tax !== undefined ? service.tax : null,
                service_category:
                  service.service_category !== undefined
                    ? service.service_category
                    : null,
                description_public:
                  service.description_public !== undefined
                    ? service.description_public
                    : "",
                description_private:
                  service.description_private !== undefined
                    ? service.description_private
                    : "",
              };
            });
            this.setState({
              services: apiData,
            });
          } else if (response.status === 401) {
            return null;
          }
        });
      });
  };

  getServicesCategories() {
    getServicesCategories
      .getSalonServiceCategories(this.state.salon_name)
      .then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            let apiData = [
              {
                id: 0,
                name: "Brak",
              },
            ];
            data.forEach((item) => {
              apiData.push({
                id: item.id,
                name: item.name,
              });
            });
            this.setState({
              service_categories: apiData,
            });
          } else if (response.status === 401) {
            return null;
          }
        });
      });
  }

  tog_standard() {
    this.getServicesCategories();
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));

    this.removeBodyCss();

    const { editedInputValue, selectedCategoryGroup } = this.state;

    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      // editServiceValues['service_category'] = selectedCategoryGroup.value;
      this.setState({
        editedInputValue: {
          label: selectedCategoryGroup.label,
          value: selectedCategoryGroup.value,
        },
      });
    } else {
      this.setState({ editedInputValue: { label: "Brak", value: 0 } });
    }
  }

  tog_editCustomer() {
    this.setState((prevState) => ({
      modal_editCustomer: !prevState.modal_editCustomer,
    }));
    this.removeBodyCss();
  }

  async togAddService() {
    this.getServicesCategories();
    this.setState((prevState) => ({
      modal_addCustomer: !prevState.modal_addCustomer,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  addService() {
    let addService = [];
    const addCustomerFormFields = document.querySelectorAll(".addServiceField");
    const { selectedCategoryGroup, selectedTaxGroup, priceRegex } = this.state;
    addCustomerFormFields.forEach((field) => {
      addService[field.name] = field.value;
    });

    if (selectedTaxGroup) {
      addService["tax"] = selectedTaxGroup.value;
    } else {
      addService["tax"] = this.state.optionTaxGroup[0].options[0].value;
    }
    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      addService["service_category"] = selectedCategoryGroup.value;
    }

    const regexPriceValue = addService.price.match(this.state.priceRegex);
    if (addService.title === "") {
      this.setState({ checkValid: false });
    } else if (addService.service_time_min === "") {
      this.setState({ checkValid: false });
    } else if (addService.service_time_max === "") {
      this.setState({ checkValid: false });
    } else if (!regexPriceValue || regexPriceValue[0] !== addService.price) {
      this.setState({ checkValid: false });
    } else {
      this.setState({ checkValid: true });
    }

    addService["price"] = parseFloat(this.state.totalGrossPrice);
    addService["service_time_min"] = parseInt(addService["service_time_min"]);
    addService["service_time_max"] = parseInt(addService["service_time_max"]);
    const dataBody = Object.assign({}, addService);

    if (this.state.checkValid) {
      const addCustomerAlert = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      addCustomerAlert
        .fire({
          title: "Sukces",
          text: "Pomyślnie dodano usługę.",
          icon: "success",
          confirmButtonText: "Ok",
        })
        .then((result) => {
          this.togAddService();
          if (result.isConfirmed) {
            postServices
              .postSalonService(this.state.salon_name, dataBody)
              .then((response) => {
                if (response.status === 201) {
                  this.getServices();
                } else if (response.status === 401) {
                  this.errorMessage();
                  return null;
                }
              });
            this.setState({ selectedCategoryGroup: null });
          }
        });
    } else {
      return ToastNotifications(
        "error",
        "Popraw formularz i spróbuj ponownie dodać usługe",
        "Błąd w formularzu",
      );
    }
  }

  editService(serviceId) {
    const { selectedCategoryGroup, selectedTaxGroup } = this.state;
    let editServiceValues = [];
    const editCustomerFormFields =
      document.querySelectorAll(".editServiceField");
    editCustomerFormFields.forEach((field) => {
      editServiceValues[field.name] = field.value;
    });

    if (!selectedTaxGroup || selectedTaxGroup.value === undefined) {
      editServiceValues["tax"] = this.state.optionTaxGroup[0].options[0].value;
    } else {
      editServiceValues["tax"] = selectedTaxGroup.value;
    }
    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      editServiceValues["service_category"] = selectedCategoryGroup.value;
    }

    const regexPriceValue = editServiceValues.price.match(
      this.state.priceRegex,
    );
    if (editServiceValues.title === "") {
      this.setState({ checkValid: false });
    } else if (editServiceValues.service_time_min === "") {
      this.setState({ checkValid: false });
    } else if (editServiceValues.service_time_max === "") {
      this.setState({ checkValid: false });
    } else if (
      !regexPriceValue ||
      regexPriceValue[0] !== editServiceValues.price
    ) {
      this.setState({ checkValid: false });
    } else {
      this.setState({ checkValid: true });
    }

    // editServiceValues['price'] = parseFloat(editServiceValues['price']);
    editServiceValues["service_time_min"] = parseInt(
      editServiceValues["service_time_min"],
    );
    editServiceValues["service_time_max"] = parseInt(
      editServiceValues["service_time_max"],
    );
    const dataBody = Object.assign({}, editServiceValues);

    const editMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    if (this.state.checkValid) {
      editMsg
        .fire({
          title: "Sukces!",
          text: "Kontakt został zmieniony.",
          icon: "success",
          confirmButtonText: "Ok",
        })
        .then((result) => {
          this.tog_standard();
          if (result.isConfirmed) {
            patchServices
              .updateServiceData(this.state.salon_name, serviceId, dataBody)
              .then((response) => {
                if (response.status === 200) {
                  this.getServices();
                } else if (response.status === 401) {
                  return null;
                }
              });
          }
        });
    } else return;
  }

  removeService(service_id) {
    const deleteMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteServices
            .deleteSalonService(this.state.salon_name, service_id)
            .then((response) => {
              if (response.status === 200) {
                this.getServices();
                this.getInactiveServices();
              } else if (response.status === 401) {
                return null;
              }
            });
          deleteMsg.fire("Usunięto!", "Usługa została usunięta.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          deleteMsg.fire("Anulowano", "Usługa zachowana", "error");
        }
      });
  }

  undoService(service_id) {
    const undoMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    undoMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Usługa zostanie przywrócona!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          patchServices
            .undoServiceRemoval(this.state.salon_name, service_id)
            .then((response) => {
              if (response.status === 200) {
                this.getServices();
                this.getInactiveServices();
              } else if (response.status === 401) {
                return null;
              }
            });
          undoMsg.fire(
            "Przywrócono!",
            "Usługa została przywrócona.",
            "success",
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          undoMsg.fire("Anulowano", "Usługa nie zmieniona", "error");
        }
      });
  }

  getServices() {
    getServices.getSalonServices(this.state.salon_name).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((service) => {
            return {
              id: service.id,
              title: service.title,
              service_time_min: service.service_time_min,
              service_time_max:
                service.service_time_max !== undefined
                  ? service.service_time_max
                  : "",
              price: service.price !== undefined ? service.price : "",
              tax: service.tax !== undefined ? service.tax : null,
              service_category:
                service.service_category !== undefined
                  ? service.service_category
                  : null,
              description_public:
                service.description_public !== undefined
                  ? service.description_public
                  : "",
              description_private:
                service.description_private !== undefined
                  ? service.description_private
                  : "",
            };
          });
          this.setState({
            services: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  getInactiveServices() {
    getServices.getInactiveServices(this.state.salon_name).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((service) => {
            return {
              id: service.id,
              title: service.title,
              service_time_min: service.service_time_min,
              service_time_max:
                service.service_time_max !== undefined
                  ? service.service_time_max
                  : "",
              price: service.price !== undefined ? service.price : "",
              tax: service.tax !== undefined ? service.tax : null,
              service_category:
                service.service_category !== undefined
                  ? service.service_category
                  : null,
              description_public:
                service.description_public !== undefined
                  ? service.description_public
                  : "",
              description_private:
                service.description_private !== undefined
                  ? service.description_private
                  : "",
            };
          });
          this.setState({
            inactiveServices: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  findService(id) {
    const { services } = this.state;
    services.find((el) => el.id === id);
  }

  getTaxes() {
    getTaxes.getAllTaxes().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((tax) => {
            return {
              id: tax.id,
              value: tax.value + "%",
            };
          });
          this.setState({
            tax_options: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  componentDidMount() {
    // localStorage.setItem('access_token', 'ZWE1YWQyOTEwZmFlYzA5ODM1MDhkMzUyZjhjOTNmNWY5YTVjMjVhODExYWY3MzM5YWFhODY3YjMxOTZkOThiOQ');
    // localStorage.setItem('salon_name','pbuklad');
    this.state.salon_name = localStorage.getItem("salon_name");
    this.getServices();
    this.getInactiveServices();
    this.getTaxes();
    this.getServicesCategories();
  }

  render() {
    const {
      services,
      inactiveServices,
      isActiveServices,
      selectedCategoryServices,
    } = this.state;
    let dataValue;

    if (isActiveServices) {
      dataValue = {
        columns: [
          {
            label: "Tytuł",
            field: "title",
            sort: "asc",
            width: 150,
          },
          {
            label: "Minimalny Czas Trwania Usługi (min)",
            field: "service_time_min",
            sort: "asc",
            width: 270,
          },
          {
            label: "Maksymalny Czas Trwania Usługi (min)",
            field: "service_time_max",
            sort: "asc",
            width: 200,
          },
          {
            label: "Cena (zł)",
            field: "price",
            sort: "asc",
            width: 100,
          },
          {
            label: "Publiczny Opis",
            field: "description_public",
            sort: "asc",
            width: 150,
          },
          {
            label: "Prywatny Opis",
            field: "description_private",
            sort: "asc",
            width: 150,
          },
          {
            label: "Edytuj",
            sort: "disable",
            field: "edit",
            sortIconBefore: false,
          },
          {
            label: "Usuń",
            sort: false,
            field: "delete",
          },
        ],
        rows: [
          ...services.map((data) => ({
            title: data.title,
            service_time_min: data.service_time_min,
            service_time_max:
              data.service_time_max !== undefined ? data.service_time_max : "",
            price: data.price !== undefined ? data.price : "",
            category: data.service_category,
            description_public:
              data.description_public !== undefined
                ? data.description_public
                : "",
            description_private:
              data.description_private !== undefined
                ? data.description_private
                : "",
            edit: (
              <MDBBtn
                onClick={() => {
                  this.tog_standard();
                  this.setState({ defaultInputValue: data });
                }}
                position="center"
                color="info"
                size="sm"
              >
                <i className="ri-edit-2-fill" />
              </MDBBtn>
            ),
            delete: (
              <MDBBtn
                onClick={() => {
                  this.removeService(data.id);
                }}
                position="center"
                color="danger"
                size="sm"
              >
                <i className="ri-delete-bin-line" />
              </MDBBtn>
            ),
          })),
        ],
      };
    } else {
      dataValue = {
        columns: [
          {
            label: "Tytuł",
            field: "title",
            sort: "asc",
            width: 150,
          },
          {
            label: "Minimalny Czas Trwania Usługi (min)",
            field: "service_time_min",
            sort: "asc",
            width: 270,
          },
          {
            label: "Maksymalny Czas Trwania Usługi (min)",
            field: "service_time_max",
            sort: "asc",
            width: 200,
          },
          {
            label: "Cena (zł)",
            field: "price",
            sort: "asc",
            width: 100,
          },
          {
            label: "Publiczny Opis",
            field: "description_public",
            sort: "asc",
            width: 150,
          },
          {
            label: "Prywatny Opis",
            field: "description_private",
            sort: "asc",
            width: 150,
          },
          {
            label: "Przywróć",
            sort: false,
            field: "undo",
          },
        ],
        rows: [
          ...inactiveServices.map((data) => ({
            title: data.title,
            service_time_min: data.service_time_min,
            service_time_max:
              data.service_time_max !== undefined ? data.service_time_max : "",
            price: data.price !== undefined ? data.price : "",
            description_public:
              data.description_public !== undefined
                ? data.description_public
                : "",
            description_private:
              data.description_private !== undefined
                ? data.description_private
                : "",
            undo: (
              <MDBBtn
                onClick={() => {
                  this.undoService(data.id);
                }}
                position="center"
                color="danger"
                size="sm"
              >
                <i className="ri-arrow-go-back-line" />
              </MDBBtn>
            ),
          })),
        ],
      };
    }

    const { tax_options } = this.state;

    const optionTaxGroup = [
      {
        options: [
          ...tax_options.map((taxData) => ({
            value: taxData.id,
            label: taxData.value,
          })),
        ],
      },
    ];

    const { defaultInputValue } = this.state;
    const { service_categories } = this.state;

    const optionCategoryGroup = [
      {
        options: [
          ...service_categories.map((categoryData) => ({
            value: categoryData.id,
            label: categoryData.name,
          })),
        ],
      },
    ];
    this.findService();
    const salonMinReservation =
      localStorage.getItem("salon_min_reservation_length") &&
      localStorage.getItem("salon_min_reservation_length") !== "undefined"
        ? localStorage.getItem("salon_min_reservation_length")
        : 1;

    const defaultPriceNett = () => {
      const defaultPrice = Number(defaultInputValue?.price);
      const defaultTax =
        defaultInputValue.tax !== undefined
          ? Number(defaultInputValue.tax.value)
          : 23;

      if (defaultPrice && defaultTax) {
        return parseFloat((defaultPrice * 100) / (100 + defaultTax)).toFixed(2);
      }

      return 0;
    };
    console.log(defaultInputValue);
    console.log(salonMinReservation);
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col sm={12} md={12} lg={12} xl={4}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <FormGroup className="select-container w-100 d-flex flex-column justify-center align-center">
                          <Categories
                            key={this.state.service_categories}
                            categories={this.state.service_categories}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={12} lg={12} xl={8}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                          Usługi
                        </h2>
                        <div className="flex-between-wrapper">
                          <div className="flex-buttons">
                            <Button
                              data-toggle="modal"
                              data-taget="#modalPostCustomer"
                              type="button"
                              onClick={this.togAddService}
                              color="primary"
                              className="waves-effect waves-light"
                            >
                              Dodaj Usługę
                            </Button>
                            <Button
                              data-toggle="modal"
                              type="button"
                              color="success"
                              className="waves-effect waves-light order-service"
                              onClick={() => {
                                this.setState({
                                  isActiveServices:
                                    !this.state.isActiveServices,
                                });
                              }}
                            >
                              {this.state.isActiveServices
                                ? "Nieaktywne usługi"
                                : "Aktywne usługi"}
                            </Button>
                            <Link to="uslugi-publiczne">
                              <Button
                                data-toggle="modal"
                                type="button"
                                color="success"
                                className="waves-effect waves-light order-service ml-0"
                              >
                                Zamów usługę
                              </Button>
                            </Link>
                          </div>
                          <FormGroup className="select-container d-flex flex-column">
                            <Select
                              defaultValue={optionCategoryGroup[0].options[0]}
                              onChange={this.handleSelectCategory}
                              options={optionCategoryGroup}
                              isSearchable={false}
                              placeholder="Sortuj po kategorii"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody className="custom-data-table">
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={dataValue}
                      {...translations.datatable}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="service__modal"
                isOpen={this.state.modal_addCustomer}
                toggle={this.togAddService}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_addCustomer: false })}
                >
                  Dodaj usługę
                </ModalHeader>
                <ModalBody>
                  <AvForm onSubmit={this.addService} id="addServiceForm">
                    <AvField
                      id="addServiceTitle"
                      className="addServiceField"
                      onChange={this.handleCheckField}
                      name="title"
                      label="Tytuł"
                      placeholder="Wprowadź tytuł"
                      type="text"
                      errorMessage="Wprowadź tytuł"
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      id="addServiceMinTime"
                      className="addServiceField"
                      name="service_time_min"
                      label="Minimalny czas usługi (w min.)"
                      type="number"
                      placeholder=" czas usługi (w min.)"
                      errorMessage={`Minimalny czas usługi nie powinien być mniejszy niż ${salonMinReservation}`}
                      min={Number(salonMinReservation)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            "To pole jest wymagane. Wprowadź minimalny czas usługi",
                        },
                      }}
                    />
                    <AvField
                      id="addServiceMaxTime"
                      className="addServiceField"
                      onChange={this.handleCheckField}
                      name="service_time_max"
                      label="Maksymalny czas usługi (w min.)"
                      type="number"
                      placeholder="czas usługi (w min.)"
                      errorMessage={`Maksymalny czas usługi nie powinien być mniejszy niż ${salonMinReservation}`}
                      min={Number(salonMinReservation)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            "To pole jest wymagane. Wprowadź maksymalny czas usługi",
                        },
                      }}
                    />
                    <FormGroup className="select2-container">
                      <Label>Podatek</Label>
                      <Select
                        defaultValue={optionTaxGroup[0].options[0]}
                        onChange={this.handleSelectTaxGroup}
                        options={optionTaxGroup}
                        classNamePrefix="select2-selection"
                        isSearchable={false}
                      />
                    </FormGroup>
                    <AvField
                      name="priceNet"
                      label="Cena netto"
                      type="number"
                      placeholder="Wprowadź cenę netto"
                      errorMessage="Wprowadź prawidłową cenę netto"
                      validate={{ required: { value: true } }}
                      step="0.01"
                      min="0.00"
                      value={
                        this.state.totalNetPrice === 0
                          ? ""
                          : this.state.totalNetPrice
                      }
                      onChange={(e) => {
                        this.handlePrices(e, "net");
                      }}
                    />
                    <AvField
                      id="addPrice"
                      className="addServiceField"
                      name="price"
                      label="Cena brutto"
                      type="number"
                      placeholder="Wprowadź cenę brutto"
                      errorMessage="Wprowadź prawidłową cenę brutto"
                      validate={{ required: { value: true } }}
                      step="0.01"
                      min="0.00"
                      value={
                        this.state.totalGrossPrice === 0
                          ? ""
                          : this.state.totalGrossPrice
                      }
                      onChange={(e) => {
                        this.handlePrices(e, "gross");
                      }}
                    />
                    <FormGroup className="select3-container">
                      <Label>Kategoria usługi</Label>
                      <Select
                        defaultValue={optionCategoryGroup[0].options[0]}
                        onChange={this.handleSelectCategoryGroup}
                        options={optionCategoryGroup}
                        classNamePrefix="select3-selection"
                        isSearchable={false}
                      />
                    </FormGroup>
                    <AvField
                      className="addServiceField"
                      name="description_public"
                      label="Publiczny opis"
                      type="text"
                      errorMessage="Wybierz podatek"
                      validate={{ required: { value: false } }}
                      id="addDescriptionPublic"
                    />
                    <AvField
                      className="addServiceField"
                      name="description_private"
                      label="Prywatny opis"
                      placeholder="Wprowadź prywatny opis"
                      type="text"
                      validate={{
                        required: { value: false },
                      }}
                      id="addDescriptionPrivate"
                    />
                    <FormGroup className="mb-0">
                      <div>
                        <Button
                          id="add_new_service"
                          type="submit"
                          color="primary"
                          className="mr-1"
                        >
                          Dodaj
                        </Button>{" "}
                        <Button
                          onClick={this.togAddService}
                          type="reset"
                          color="secondary"
                        >
                          Anuluj
                        </Button>
                      </div>
                    </FormGroup>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>

        {/* EDIT MODAL */}
        <Container>
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <Modal
                isOpen={this.state.modal_standard}
                toggle={this.tog_standard}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard: false })}
                >
                  Edytuj usługę
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onSubmit={() => {
                      this.editService(this.state.defaultInputValue.id);
                    }}
                    id="editServiceForm"
                  >
                    <AvField
                      id="editServiceTitle"
                      className="editServiceField"
                      onChange={this.handleCheckField}
                      name="title"
                      label="Tytuł"
                      placeholder="Wprowadź tytuł"
                      type="text"
                      errorMessage="Wprowadź tytuł"
                      validate={{ required: { value: true } }}
                      value={defaultInputValue.title}
                    />
                    <AvField
                      id="editServiceMinTime"
                      className="editServiceField"
                      name="service_time_min"
                      label="Minimalny czas usługi (w min.)"
                      type="number"
                      placeholder=" czas usługi (w min.)"
                      value={defaultInputValue.service_time_min}
                      errorMessage={`Minimalny czas usługi nie powinien być mniejszy niż ${salonMinReservation}`}
                      min={Number(salonMinReservation)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            "To pole jest wymagane. Wprowadź minimalny czas usługi",
                        },
                      }}
                    />
                    <AvField
                      id="editServiceMaxTime"
                      className="editServiceField"
                      onChange={this.handleCheckField}
                      name="service_time_max"
                      label="Maksymalny czas usługi (w min.)"
                      type="number"
                      placeholder="czas usługi (w min.)"
                      value={defaultInputValue.service_time_max}
                      errorMessage={`Maksymalny czas usługi nie powinien być mniejszy niż ${salonMinReservation}`}
                      min={Number(salonMinReservation)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage:
                            "To pole jest wymagane. Wprowadź maksymalny czas usługi",
                        },
                      }}
                    />
                    <FormGroup className="select4-container">
                      <Label>Podatek</Label>
                      <Select
                        defaultValue={{
                          label:
                            defaultInputValue.tax !== undefined
                              ? defaultInputValue.tax.value + "%"
                              : null,
                          value:
                            defaultInputValue.tax !== undefined
                              ? defaultInputValue.tax.id
                              : null,
                        }}
                        onChange={this.handleSelectTaxGroup}
                        options={optionTaxGroup}
                        classNamePrefix="select4-selection"
                        isSearchable={false}
                      />
                    </FormGroup>
                    <AvField
                      name="priceNet"
                      label="Cena netto"
                      type="number"
                      placeholder="Wprowadź cenę netto"
                      errorMessage="Wprowadź prawidłową cenę netto"
                      validate={{ required: { value: true } }}
                      step="0.01"
                      min="0.00"
                      value={
                        this.state.totalNetPrice === 0
                          ? defaultPriceNett()
                          : this.state.totalNetPrice
                      }
                      onChange={(e) => {
                        this.handlePrices(e, "net");
                      }}
                    />
                    <AvField
                      name="price"
                      className="editServiceField"
                      label="Cena brutto"
                      type="number"
                      placeholder="Wprowadź cenę brutto"
                      errorMessage="Wprowadź prawidłową cenę brutto"
                      validate={{ required: { value: true } }}
                      step="0.01"
                      min="0.00"
                      value={
                        this.state.totalGrossPrice === 0
                          ? parseFloat(defaultInputValue.price).toFixed(2)
                          : this.state.totalGrossPrice
                      }
                      onChange={(e) => {
                        this.handlePrices(e, "gross");
                      }}
                    />
                    <FormGroup className="select5-container">
                      <Label>Kategoria usługi</Label>
                      <Select
                        defaultValue={
                          defaultInputValue?.service_category?.id
                            ? {
                                label:
                                  defaultInputValue?.service_category?.name ||
                                  "Brak",
                                value:
                                  defaultInputValue?.service_category?.id || 0,
                              }
                            : null
                        }
                        placeholder="Wybierz kategorię"
                        onChange={this.handleSelectCategoryGroup}
                        options={optionCategoryGroup}
                        classNamePrefix="select5-selection"
                        isSearchable={false}
                      />
                    </FormGroup>
                    <AvField
                      value={defaultInputValue.description_public}
                      className="editServiceField"
                      name="description_public"
                      label="Publiczny opis"
                      type="text"
                      errorMessage="Wybierz podatek"
                      validate={{ required: { value: false } }}
                      id="editDescriptionPublic"
                    />
                    <AvField
                      className="editServiceField"
                      name="description_private"
                      label="Prywatny opis"
                      placeholder="Wprowadź prywatny opis"
                      type="text"
                      validate={{
                        required: { value: false },
                      }}
                      id="editDescriptionPrivate"
                      value={defaultInputValue.description_private}
                    />
                    <FormGroup className="mb-0">
                      <div>
                        <Button
                          id="edit_customer"
                          type="submit"
                          color="primary"
                          className="mr-1"
                        >
                          Zapisz
                        </Button>{" "}
                        <Button
                          onClick={this.tog_standard}
                          type="reset"
                          color="secondary"
                        >
                          Anuluj
                        </Button>
                      </div>
                    </FormGroup>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Services;
