import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { postWarehouses } from "../../Api/Warehouses/postWarehouses";
import { getOwnedWarehouses } from "../../Api/Warehouses/getWarehouses";
import { getSalon } from "../../Api/Salons/getSelectedSalon";
import { updateWarehouses } from "../../Api/Warehouses/patchWarehouses";
import { getAllSalonsNoPublic } from "../../Api/Salons/getSalonsWithToken";
import { deleteWarehouse } from "../../Api/Warehouses/deleteWarehouses";
import Swal from "sweetalert2";
import Select from "react-select";
import { MDBDataTable, MDBBtn } from "mdbreact";

import translations from "../../helpers/translations/pl";

class Warehouses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: localStorage.getItem("salon_name"),
      warehouse_name: localStorage.getItem("warehouse_name"),
      remoteData: [],
      modal_addWarehouse: false,
      modal_editWarehouse: false,
      editWarehouseData: null,
      selectedIsMain: false,
      selectedIsVirtual: true,
      optionIsMain: [
        { value: true, label: "Tak" },
        { value: false, label: "Nie" },
      ],
      optionIsVirtual: [
        { value: true, label: "Tak" },
        { value: false, label: "Nie" },
      ],
      currentSalonId: 0,
    };
    this.fetchWarehouses = this.fetchWarehouses.bind(this);
    this.togAddWarehouse = this.togAddWarehouse.bind(this);
    this.postWarehouse = this.postWarehouse.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getCurrentSalonId = this.getCurrentSalonId.bind(this);
    this.editWarehouse = this.editWarehouse.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.updateWarehouse = this.updateWarehouse.bind(this);
    this.deleteWarehouse = this.deleteWarehouse.bind(this);
  }

  componentDidMount() {
    this.fetchWarehouses();
    this.getCurrentSalonId();
  }

  async getCurrentSalonId() {
    try {
      const response = await getSalon(this.state.salon_name);
      const data = await response.json();
      if (response.ok) {
        this.setState({ currentSalonId: data.id });
      }
    } catch (error) {
      console.error("Błąd pobierania id salonu:", error);
    }
  }

  async fetchWarehouses() {
    try {
      const response = await getOwnedWarehouses();
      const data = await response.json();
      if (response.ok) {
        const sortedData = data.sort((a, b) => a.id - b.id);
        this.setState({ remoteData: sortedData });
      }
    } catch (error) {
      console.error("Błąd pobierania magazynów:", error);
    }
  }

  // async fetchWorkspace() {
  //     try {
  //         const response = await getWorkspace();
  //         const data = await response.json();
  //         if (data) {
  //             const workspaceId = data.id;
  //             this.setState({ currentWorkspaceId: workspaceId });
  //         }
  //     } catch (error) {
  //         console.error("Błąd pobierania danych o workspace'u:", error);
  //     }
  // }

  async fetchSalons() {
    try {
      const response = await getAllSalonsNoPublic();
      const data = await response.json();
      this.setState({ salons: data });

      // Ustaw aktualne id salonu
      const currentSalonId = data.length > 0 ? data[0].id : null;
      this.setState({ currentSalonId });
    } catch (error) {
      console.error("Błąd pobierania danych o salonach:", error);
    }
  }

  togAddWarehouse() {
    this.setState((prevState) => ({
      modal_addWarehouse: !prevState.modal_addWarehouse,
    }));
  }

  async postWarehouse(data) {
    const { currentSalonId, selectedIsMain } = this.state;
    const { name, description } = data;
    const is_virtual = this.state.selectedIsVirtual;

    const requestData = {
      name,
      description,
      salon: currentSalonId,
      is_virtual,
      is_main: selectedIsMain,
    };

    try {
      const response = await postWarehouses(requestData);
      if (response.ok) {
        this.fetchWarehouses();
        this.togAddWarehouse();
        Swal.fire({
          icon: "success",
          title: "Sukces!",
          text: "Magazyn został pomyślnie dodany.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd!",
          text: "Dodawanie magazynu nie powiodło się. Spróbuj ponownie.",
        });
      }
    } catch (error) {
      console.error("Błąd podczas dodawania magazynu:", error);
      Swal.fire({
        icon: "error",
        title: "Błąd!",
        text: "Wystąpił błąd podczas dodawania magazynu. Spróbuj ponownie później.",
      });
    }
  }

  async updateWarehouse(warehouse_id, data) {
    try {
      const response = await updateWarehouses(warehouse_id, data);
      if (response.ok) {
        this.fetchWarehouses();
        this.setState({ modal_editWarehouse: false }); // Close the modal here
        Swal.fire({
          icon: "success",
          title: "Sukces!",
          text: "Magazyn został pomyślnie zaktualizowany.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd!",
          text: "Aktualizacja magazynu nie powiodła się. Spróbuj ponownie.",
        });
      }
    } catch (error) {
      console.error("Błąd podczas aktualizacji magazynu:", error);
      Swal.fire({
        icon: "error",
        title: "Błąd!",
        text: "Wystąpił błąd podczas aktualizacji magazynu. Spróbuj ponownie później.",
      });
    }
  }

  async deleteWarehouse(warehouse_id) {
    try {
      const response = await deleteWarehouse(warehouse_id);
      if (response.ok) {
        this.fetchWarehouses();
        this.setState({ modal_editWarehouse: false }); // Close the modal here
        Swal.fire({
          icon: "success",
          title: "Sukces!",
          text: "Magazyn został pomyślnie usunięty.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd!",
          text: "Usuwanie magazynu nie powiodło się. Spróbuj ponownie.",
        });
      }
    } catch (error) {
      console.error("Błąd podczas usuwania magazynu:", error);
      Swal.fire({
        icon: "error",
        title: "Błąd!",
        text: "Wystąpił błąd podczas usuwania magazynu. Spróbuj ponownie później.",
      });
    }
  }

  async handleSubmit(event, errors, values) {
    if (errors.length === 0) {
      await this.postWarehouse(values);
    }
  }

  async editWarehouse(warehouse_id, data) {
    this.setState({
      editWarehouseData: data,
      modal_editWarehouse: true,
      selectedIsMain: data.is_main,
      selectedIsVirtual: data.is_virtual,
    });
  }

  async handleEditSubmit(event, errors, values) {
    if (errors.length === 0) {
      const { editWarehouseData, selectedIsMain, selectedIsVirtual } =
        this.state;
      if (editWarehouseData) {
        const { id } = editWarehouseData;
        const updatedData = {
          ...values,
          is_main: selectedIsMain,
          is_virtual: selectedIsVirtual,
        };
        await this.updateWarehouse(id, updatedData);
        this.setState({ modal_editWarehouse: false });
      }
    }
  }

  render() {
    const { remoteData, optionIsMain, optionIsVirtual } = this.state;

    const dataValue = {
      columns: [
        {
          label: "ID Magazynu",
          field: "id",
          sort: "asc",
          width: 10,
        },
        {
          label: "Nazwa magazynu",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Opis magazynu",
          field: "description",
          sort: "asc",
          width: 350,
        },
        {
          label: "Wirtualny magazyn?",
          field: "is_virtual",
          sort: "asc",
          width: 10,
        },
        {
          label: "Edytuj",
          field: "edit",
          sort: "disable",
          width: 50,
        },
      ],
      rows: remoteData.map((warehouse, index) => ({
        id: warehouse.id,
        name: warehouse.name,
        description: warehouse.description,
        is_virtual: warehouse.is_virtual ? "Tak" : "Nie",
        edit: (
          <MDBBtn
            onClick={() => this.editWarehouse(warehouse.id, warehouse)}
            position="center"
            color="info"
            size="sm"
          >
            <i className="ri-edit-2-fill" />
          </MDBBtn>
        ),
      })),
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                  <div className="hk-pg-header align-items-top">
                    <div>
                      <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                        Magazyny
                      </h2>
                      <div className="flex-buttons">
                        <Button
                          data-toggle="modal"
                          data-target="#modalPostCustomer"
                          type="button"
                          onClick={this.togAddWarehouse}
                          color="primary"
                          className="waves-effect waves-light"
                        >
                          Dodaj magazyn
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    striped
                    data={dataValue}
                    {...translations.datatable}
                  />
                  {remoteData.length === 0 && <p>Brak dostępnych magazynów.</p>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="warehouse__modal"
                isOpen={this.state.modal_addWarehouse}
                toggle={this.togAddWarehouse}
              >
                <ModalHeader toggle={this.togAddWarehouse}>
                  Dodaj magazyn
                </ModalHeader>
                <ModalBody>
                  <AvForm onSubmit={this.handleSubmit} id="addWarehouseForm">
                    <Container>
                      <Row>
                        <Col lg={12}>
                          <AvField
                            id="addWarehouseName"
                            className="addWarehouseField"
                            name="name"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę magazynu"
                            type="text"
                            errorMessage="Wprowadź nazwę"
                            validate={{ required: true }}
                          />
                          <AvField
                            id="addWarehouseDescription"
                            className="addWarehouseDescription"
                            name="description"
                            label="Opis magazynu"
                            placeholder="Wprowadź opis magazynu"
                            type="text"
                            validate={{ required: false }}
                          />
                          <FormGroup>
                            <Label for="selectIsMain">
                              Czy główny magazyn?
                            </Label>
                            <Select
                              id="selectIsMain"
                              defaultValue={optionIsMain[0]}
                              onChange={(selectedOption) =>
                                this.setState({
                                  selectedIsMain: selectedOption.value,
                                })
                              }
                              options={optionIsMain}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="selectIsVirtual">
                              Czy wirtualny magazyn?
                            </Label>
                            <Select
                              id="selectIsVirtual"
                              defaultValue={optionIsVirtual[1]}
                              onChange={(selectedOption) =>
                                this.setState({
                                  selectedIsVirtual: selectedOption.value,
                                })
                              }
                              options={optionIsVirtual}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                id="add_new_warehouse"
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                Dodaj
                              </Button>{" "}
                              <Button
                                onClick={this.togAddWarehouse}
                                type="reset"
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
              <Modal
                contentClassName="warehouse__modal"
                isOpen={this.state.modal_editWarehouse}
                toggle={() => this.setState({ modal_editWarehouse: false })}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_editWarehouse: false })}
                >
                  Edytuj magazyn
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onSubmit={this.handleEditSubmit}
                    id="editWarehouseForm"
                  >
                    <Container>
                      <Row>
                        <Col lg={12}>
                          <AvField
                            id="editWarehouseName"
                            className="editWarehouseField"
                            name="name"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę magazynu"
                            type="text"
                            errorMessage="Wprowadź nazwę"
                            value={
                              this.state.editWarehouseData
                                ? this.state.editWarehouseData.name
                                : ""
                            }
                            validate={{ required: true }}
                          />
                          <AvField
                            id="editWarehouseDescription"
                            className="editWarehouseDescription"
                            name="description"
                            label="Opis magazynu"
                            placeholder="Wprowadź opis magazynu"
                            type="text"
                            value={
                              this.state.editWarehouseData
                                ? this.state.editWarehouseData.description
                                : ""
                            }
                            validate={{ required: false }}
                          />
                          <FormGroup>
                            <Label for="selectIsMain">
                              Czy główny magazyn?
                            </Label>
                            <Select
                              id="selectIsMain"
                              defaultValue={
                                this.state.editWarehouseData
                                  ? {
                                      value:
                                        this.state.editWarehouseData.is_main,
                                      label: this.state.editWarehouseData
                                        .is_main
                                        ? "Tak"
                                        : "Nie",
                                    }
                                  : optionIsMain[0]
                              }
                              onChange={(selectedOption) =>
                                this.setState({
                                  selectedIsMain: selectedOption.value,
                                })
                              }
                              options={optionIsMain}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="selectIsVirtual">
                              Czy wirtualny magazyn?
                            </Label>
                            <Select
                              id="selectIsVirtual"
                              defaultValue={
                                this.state.editWarehouseData
                                  ? {
                                      value:
                                        this.state.editWarehouseData.is_virtual,
                                      label: this.state.editWarehouseData
                                        .is_virtual
                                        ? "Tak"
                                        : "Nie",
                                    }
                                  : optionIsVirtual[1]
                              }
                              onChange={(selectedOption) =>
                                this.setState({
                                  selectedIsVirtual: selectedOption.value,
                                })
                              }
                              options={optionIsVirtual}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                id="edit_warehouse"
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                Zapisz zmiany
                              </Button>{" "}
                              <Button
                                onClick={() =>
                                  this.setState({ modal_editWarehouse: false })
                                }
                                type="button"
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col
                          lg={12}
                          className="border-top mt-3"
                          style={{ borderColor: "#eff2f7" }}
                        >
                          <FormGroup className="mb-0 d-flex justify-content-end">
                            <div className="d-flex">
                              <Button
                                onClick={() =>
                                  this.deleteWarehouse(
                                    this.state.editWarehouseData.id,
                                  )
                                }
                                color="danger"
                                className="mt-3"
                              >
                                Usuń Magazyn
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Warehouses;
