import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logowhite from "../../assets/images/logo-light.png";
import Swal from "sweetalert2";

import PublicHeader from "../../components/Header/Header";
import HeaderService from "../../components/HeaderService/HeaderService";
import StepProgressBar from "react-step-progress";

//Api
import * as postOautch from "../../Api/Oauth/postOauth";
import * as getUsers from "../../Api/Users/getUsers";
import { CLIENT_ID, CLIENT_SECRET } from "../../utils/const";

class ServiceLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "admin@themesdesign.in",
      password: "123456",
      data: null,
      user: [],
      isCustomerLogin: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserLogin = this.handleUserLogin.bind(this);
  }

  handleSubmit(event, values) {
    this.props.checkLogin(values, this.props.history);
  }

  handleUserLogin() {
    const { hostApi, host } = this.state;
    const email = document.querySelector("#username");
    const password = document.querySelector("#password");
    let data = {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: email.value,
      password: password.value,
    };
    const dataBody = Object.assign({}, data);
    console.log(dataBody);
    const loginAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });

    postOautch.postOauthToken(dataBody).then((response) => {
      if (response.status === 200) {
        loginAlert
          .fire({
            title: "Sukces",
            text: "Zalogowano pomyślnie.",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              response.json().then((data) => {
                localStorage.setItem("access_token", data.access_token);
                localStorage.setItem("refresh_token", data.refresh_token);
                getUsers.getLoggedUserInfo().then((response) => {
                  response.json().then((data2) => {
                    if (response.status === 200) {
                      localStorage.setItem("salon_name", data2.salon_name);
                      localStorage.setItem("first_name", data2.first_name);
                      localStorage.setItem("last_name", data2.last_name);
                      localStorage.setItem("username", data2.username);
                      localStorage.setItem("user_id", data2.id);

                      localStorage.setItem(
                        "is_owner",
                        data2.roles.indexOf("ROLE_OWNER") >= 0,
                      );
                      localStorage.setItem(
                        "is_sa",
                        data2.roles.indexOf("ROLE_SUPER_ADMIN") >= 0,
                      );
                      localStorage.setItem(
                        "is_employee",
                        data2.roles.indexOf("ROLE_EMPLOYEE") >= 0,
                      );
                      localStorage.setItem(
                        "is_customer",
                        data2.roles.indexOf("ROLE_CUSTOMER") >= 0,
                      );
                      const defaultCalendarData =
                        data2?.defaultCalendarView &&
                        data2?.defaultCalendarView > 0
                          ? data2?.defaultCalendarView - 1
                          : 3;
                      localStorage.setItem(
                        "defaultCalendarView",
                        defaultCalendarData.toString(),
                      );

                      window.location.replace(
                        process.env.REACT_APP_HOST + "/podsumowanie",
                      );
                    }
                  });
                });
              });
            }
          });
      } else if (response.status === 400) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Niepoprawny login/hasło!",
        });
      }
    });
  }

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <HeaderService />
          <StepProgressBar
            buttonWrapperClass="hide"
            previousBtnDisabled
            startingStep={2}
            steps={[
              {
                label: "Usługi",
                name: "step 1",
                // content: step1Content
              },
              {
                label: "Termin",
                name: "step 2",
                // content: step2Content,
                // validator: step2Validator
              },
              {
                label: "Logowanie/Rejestracja",
                name: "step 3",
                // content: step2Content,
                // validator: step2Validator
              },
              {
                label: "Podsumowanie",
                name: "step 4",
                // content: step3Content,
                // validator: step3Validator
              },
            ]}
          />
          <Container className="p-0">
            <Row className="no-gutters">
              <Col lg={6}>
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logodark} height="20" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">Zaloguj się</h4>
                            <p className="text-muted">
                              Zacznij korzystać z aplikacji Visitio
                            </p>
                          </div>

                          {/* <Button color="primary">Zaloguj jako klient</Button> */}
                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            {/* <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} > */}
                            <AvForm
                              id="userLoginForm"
                              className="form-horizontal"
                              onValidSubmit={this.handleUserLogin}
                            >
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon" />
                                <Label htmlFor="username">Adres Email</Label>
                                <AvField
                                  name="username"
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Wprowadź adres e-mail"
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon" />
                                <Label htmlFor="userpassword">Hasło</Label>
                                <AvField
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  id="password"
                                  placeholder="Wprowadź hasło"
                                />
                              </FormGroup>

                              <div className="custom-control custom-checkbox">
                                <Input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customControlInline"
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="customControlInline"
                                >
                                  Zapamiętaj mnie
                                </Label>
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light fluid login-btn p-3"
                                  type="submit"
                                >
                                  Zaloguj się
                                </Button>
                              </div>

                              <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1" />{" "}
                                  Zapomniałeś/aś hasło?
                                </Link>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="align-items-center">
                  <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
                    <h3 className="font-weight-medium title mb-4">
                      Nie masz konta?
                    </h3>
                    <p className=" description mb-4">
                      Rejestracja pozwoli Ci szybko rezerwować nowe wizyty
                    </p>
                    <Link to="/koszyk-rejestracja" className="w-100">
                      <Button color="primary" className="w-100 p-3">
                        Zarejestruj się
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(ServiceLoginPage),
);
