//Get warehouse product category details
export const getWarehouseProductsCategory = async (category_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-product-categories/${category_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getWarehouseProductsCategories = async () => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-product-categories`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
