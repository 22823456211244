const renderCalendarView = () => {
  return ["timeGridDay", "timeGridWeek", "dayGridMonth", "recepcja"];
};

const renderDefaultViewOptions = () => {
  return [
    {
      value: 1,
      label: "Dzień",
    },
    {
      value: 2,
      label: "Tydzień",
    },
    {
      value: 3,
      label: "Miesiąc",
    },
    {
      value: 4,
      label: "Recepcja",
    },
  ];
};

export { renderCalendarView, renderDefaultViewOptions };
