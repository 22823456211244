import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import { getWarehouseProductsInWorkspace } from "../../Api/Warehouses/Products/getWarehouseProducts";
import { postWarehouseProducts } from "../../Api/Warehouses/Products/postWareHouseProducts";
import { updateWarehouseProduct } from "../../Api/Warehouses/Products/patchWarehouseProducts";
import { deleteWarehouseProductsCategories } from "../../Api/Warehouses/Products/deleteWarehouseProducts";
import { getWarehouseProductsCategories } from "../../Api/Warehouses/ProductCategories/getWarehouseProductsCategories";

import translations from "../../helpers/translations/pl";

class WarehouseProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: localStorage.getItem("salon_name"),
      body: [],
      modal_editProduct: false,
      modal_addProduct: false,
      defaultInputValue: [],
      editedInputValue: [],
      // PRODUCT DATA
      products: [],
      tax_options: [],
      products_categories: [],
      consumption_units: [],
      products_types: [],
      priceRegex: /^\d+(\.\d{0,2}|,\d{0,2})?$/,
      // SELECT OPTIONS
      selectedTaxGroup: null,
      selectedProductTypesGroup: null,
      selectedConsumptionUnitsGroup: null,
      selectedCategoryGroup: null,
      optionTaxGroup: [],
      optionProductTypesGroup: [],
      optionConsumptionUnitsGroup: [],
      optionCategoryGroup: [],
      isActiveProducts: true,
      inactiveProducts: [],
      selectedProductId: null,
    };

    this.togEditProduct = this.togEditProduct.bind(this);
    this.togAddProduct = this.togAddProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.addProduct = this.addProduct.bind(this);
    // SELECT
    this.handleSelectTaxGroup = this.handleSelectTaxGroup.bind(this);
    this.handleSelectProductTypesGroup =
      this.handleSelectProductTypesGroup.bind(this);
    this.handleSelectConsumptionUnitsGroup =
      this.handleSelectConsumptionUnitsGroup.bind(this);
    this.handleSelectCategoryGroup = this.handleSelectCategoryGroup.bind(this);
  }

  //Select
  handleSelectTaxGroup = (selectedTaxGroup) => {
    this.setState({ selectedTaxGroup });
  };

  handleSelectProductTypesGroup = (selectedProductTypesGroup) => {
    this.setState({ selectedProductTypesGroup });
  };

  handleSelectConsumptionUnitsGroup = (selectedConsumptionUnitsGroup) => {
    this.setState({ selectedConsumptionUnitsGroup });
  };

  handleSelectCategoryGroup = (selectedCategoryGroup) => {
    this.setState({ selectedCategoryGroup });
  };

  togEditProduct(product_id) {
    this.setState((prevState) => ({
      modal_editProduct: !prevState.modal_editProduct,
    }));
    this.removeBodyCss();
    const { selectedCategoryGroup } = this.state;

    if (selectedCategoryGroup && selectedCategoryGroup.value !== null) {
      this.setState({
        editedInputValue: {
          label: selectedCategoryGroup.label,
          value: selectedCategoryGroup.value,
        },
      });
    } else {
      this.setState({ editedInputValue: { label: "brak", value: 0 } });
    }

    if (product_id) {
      this.setState({ selectedProductId: product_id });
    }
  }

  togAddProduct() {
    this.setState((prevState) => ({
      modal_addProduct: !prevState.modal_addProduct,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  addProduct() {
    let postWarehouseProduct = {};
    const addProductFormFields = document.querySelectorAll(".addProductField");
    const { selectedCategoryGroup } = this.state;

    addProductFormFields.forEach((field) => {
      postWarehouseProduct[field.name] = field.value;
    });
    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      postWarehouseProduct["category"] = selectedCategoryGroup.value;
    }

    const dataBody = Object.assign({}, postWarehouseProduct);

    const addProductAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    addProductAlert
      .fire({
        title: "Sukces",
        text: "Pomyślnie dodano produkt.",
        icon: "success",
        confirmButtonText: "Ok",
      })
      .then((result) => {
        this.togAddProduct();
        if (result.isConfirmed) {
          postWarehouseProducts(dataBody).then((response) => {
            if (response.status === 201) {
              this.getProducts();
            } else if (response.status === 401) {
              this.errorMessage();
              return null;
            }
          });
          this.setState({ selectedCategoryGroup: null });
        }
      });
  }

  editProduct(product_id) {
    const { selectedCategoryGroup, selectedProductId } = this.state;

    let editProductValues = {};
    const editProductFormFields =
      document.querySelectorAll(".editProductField");
    editProductFormFields.forEach((field) => {
      editProductValues[field.name] = field.value;
    });

    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      editProductValues["category"] = selectedCategoryGroup.value;
    }

    const dataBody = Object.assign({}, editProductValues);

    const editMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    editMsg
      .fire({
        title: "Sukces!",
        text: "Produkt został zmieniony.",
        icon: "success",
        confirmButtonText: "Ok",
      })
      .then((result) => {
        this.togEditProduct();
        if (result.isConfirmed) {
          updateWarehouseProduct(selectedProductId, dataBody).then(
            (response) => {
              if (response.status === 200) {
                this.getProducts();
              } else if (response.status === 401) {
                return null;
              }
            },
          );
        }
      });
  }

  getProducts() {
    getWarehouseProductsInWorkspace()
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            let apiData = data.map((product) => {
              return {
                product_id: product.id,
                product_name: product.name,
                product_description: product.description,
                product_category: product.category,
              };
            });
            this.setState({
              products: apiData,
            });
          });
        } else if (response.status === 401) {
          return null;
        }
      })
      .catch((error) => {
        console.error("Błąd podczas pobierania produktów:", error);
      });
  }

  getProductsCategories() {
    const { category_id } = this.state;

    getWarehouseProductsCategories(category_id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      })
      .then((apiData) => {
        const formattedData = [
          {
            id: 0,
            name: "brak",
          },
          ...apiData.map((item) => ({
            id: item.id,
            name: item.name,
          })),
        ];
        this.setState({
          products_categories: formattedData,
          optionCategoryGroup: formattedData.map((categoryData) => ({
            value: categoryData.id,
            label: categoryData.name,
          })),
        });
      })
      .catch((error) => {
        console.error("Błąd podczas pobierania kategorii produktów:", error);
      });
  }

  removeProduct(product_id) {
    const deleteProductAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    deleteProductAlert
      .fire({
        title: "Czy na pewno chcesz usunąć ten produkt?",
        text: "Tej operacji nie można cofnąć.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteWarehouseProductsCategories(product_id)
            .then((response) => {
              if (response.status === 200) {
                this.getProducts();
                this.setState({ modal_editProduct: false });
              } else if (response.status === 401) {
                this.errorMessage();
                return null;
              }
            })
            .catch((error) => {
              console.error("Błąd podczas usuwania produktu:", error);
            });
        }
      });
  }

  componentDidMount() {
    this.getProducts();
    this.getProductsCategories();
  }

  render() {
    const { products, optionCategoryGroup } = this.state;
    let dataValue;
    dataValue = {
      columns: [
        {
          label: "Id",
          field: "product_id",
          sort: "asc",
          width: 10,
        },
        {
          label: "Nazwa",
          field: "product_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Opis",
          field: "product_description",
          sort: "asc",
          width: 350,
        },
        {
          label: "Kategoria Produktu",
          field: "product_category",
          sort: "asc",
          width: 270,
        },
        {
          label: "Edytuj",
          sort: "disable",
          field: "edit",
          sortIconBefore: false,
        },
      ],
      rows: [
        ...products.map((data) => ({
          product_name: data.product_name,
          product_id: data.product_id,
          product_description: data.product_description,
          product_category: data.product_category
            ? data.product_category.name
            : null,
          edit: (
            <MDBBtn
              onClick={() => {
                this.togEditProduct(data.product_id);
                this.setState({ defaultInputValue: data });
              }}
              position="center"
              color="info"
              size="sm"
            >
              <i className="ri-edit-2-fill" />
            </MDBBtn>
          ),
        })),
      ],
    };

    const { defaultInputValue } = this.state;

    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                          Produkty
                        </h2>
                        <div className="flex-buttons">
                          <Button
                            data-toggle="modal"
                            data-taget="#modalPostCustomer"
                            type="button"
                            onClick={this.togAddProduct}
                            color="primary"
                            className="waves-effect waves-light"
                          >
                            Dodaj produkt
                          </Button>
                          <Link to="/magazyn/kategorie">
                            <Button
                              type="button"
                              color="primary"
                              className="waves-effect waves-light  order-service"
                            >
                              Dodaj kategorie
                            </Button>
                          </Link>
                          <Link to="/produkty/kategorie">
                            <Button
                              type="button"
                              color="primary"
                              className="waves-effect waves-light  order-service"
                            >
                              Kategorie produktów
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody className="custom-data-table">
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={dataValue}
                      {...translations.datatable}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="product__modal"
                isOpen={this.state.modal_addProduct}
                toggle={this.togAddProduct}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_addProduct: false })}
                >
                  Dodaj produkt
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={this.addProduct} id="addProductForm">
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            id="addProductName"
                            className="addProductField"
                            name="name"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę"
                            type="text"
                            errorMessage="Wprowadź nazwę"
                            validate={{ required: true }}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormGroup className="select5-container">
                            <Label>Kategoria produktu</Label>
                            <Select
                              defaultValue={optionCategoryGroup[0]}
                              onChange={this.handleSelectCategoryGroup}
                              options={optionCategoryGroup}
                              classNamePrefix="select5-selection"
                              isSearchable={false}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <AvField
                            id="addProductDescription"
                            className="addProductField"
                            name="description"
                            label="Opis"
                            placeholder="Wprowadź opis"
                            type="textarea"
                            errorMessage="Wprowadź opis"
                            validate={{ required: true }}
                          />
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                id="add_new_product"
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                Dodaj
                              </Button>{" "}
                              <Button
                                onClick={this.togAddProduct}
                                type="reset"
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>

            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="product__modal"
                isOpen={this.state.modal_editProduct}
                toggle={this.togEditProduct}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_editProduct: false })}
                >
                  Edytuj produkt
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={this.editProduct} id="editProductForm">
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            id="editProductName"
                            className="editProductField"
                            name="name"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę"
                            type="text"
                            value={defaultInputValue.product_name}
                            errorMessage="Wprowadź nazwę"
                            validate={{ required: true }}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormGroup className="select5-container">
                            <Label>Kategoria produktu</Label>
                            <Select
                              defaultValue={this.state.editedInputValue}
                              onChange={this.handleSelectCategoryGroup}
                              options={optionCategoryGroup}
                              classNamePrefix="select5-selection"
                              isSearchable={false}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <AvField
                            id="editProductDescription"
                            className="editProductField"
                            name="description"
                            label="Opis"
                            placeholder="Wprowadź opis"
                            type="textarea"
                            value={defaultInputValue.product_description}
                            errorMessage="Wprowadź opis"
                            validate={{ required: true }}
                          />
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div className="d-flex">
                              <Button
                                type="submit"
                                color="primary"
                                className="mr-2"
                              >
                                Zapisz
                              </Button>
                              <Button
                                onClick={this.togEditProduct}
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col
                          lg={12}
                          className="border-top mt-3"
                          style={{ borderColor: "#eff2f7" }}
                        >
                          <FormGroup className="mb-0 d-flex justify-content-end">
                            <div className="d-flex">
                              <Button
                                onClick={() => {
                                  this.removeProduct(
                                    defaultInputValue.product_id,
                                  );
                                }}
                                color="danger"
                                className="mt-3"
                              >
                                Usuń Produkt
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default WarehouseProducts;
