//Lists all Warehouse Products in Workspace
export const getWarehouseProductsInWorkspace = async () => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-products`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

//Lists all Warehouse Products in category
export const getWarehouseProductsInCategories = async (category_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-products/categories/${category_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getWarehouseProduct = async (product_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-products/${product_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
