import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { AvForm, AvField } from "availity-reactstrap-validation";
import translations from "../../helpers/translations/pl";
import { Link } from "react-router-dom";

import {
  getWarehouseProductsCategories,
  getWarehouseProductsCategory,
} from "../../Api/Warehouses/ProductCategories/getWarehouseProductsCategories";
import { postWarehouseProductsCategories } from "../../Api/Warehouses/ProductCategories/postWarehousesProductsCategories";
import { updateWarehouseProductsCategories } from "../../Api/Warehouses/ProductCategories/patchWarehouseProductsCategories";
import { deleteWarehouseProductsCategories } from "../../Api/Warehouses/ProductCategories/deleteWarehouseProductsCategories";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: localStorage.getItem("salon_name"),
      modal_editCategory: false,
      modal_addCategory: false,
      defaultInputValue: [],
      editedInputValue: [],
      products_categories: [],
      selectedCategoryGroup: null,
      optionCategoryGroup: [],
      selectedCategoryId: null,
      newCategoryName: "",
      newCategoryParentId: null,
      newCategoryDescription: "",
    };
    this.addCategory = this.addCategory.bind(this);
    this.editCategory = this.editCategory.bind(this);
    this.saveEditedCategory = this.saveEditedCategory.bind(this);
    this.handleSelectCategoryGroup = this.handleSelectCategoryGroup.bind(this);
    this.toggleAddCategory = this.toggleAddCategory.bind(this);
    this.toggleEditCategory = this.toggleEditCategory.bind(this);
    this.handleChangeNewCategoryName =
      this.handleChangeNewCategoryName.bind(this);
    this.handleChangeNewCategoryParentId =
      this.handleChangeNewCategoryParentId.bind(this);
    this.handleChangeNewCategoryDescription =
      this.handleChangeNewCategoryDescription.bind(this);
    this.handleChangeEditedCategoryName =
      this.handleChangeEditedCategoryName.bind(this);
    this.handleChangeEditedCategoryParentId =
      this.handleChangeEditedCategoryParentId.bind(this);
    this.handleChangeEditedCategoryDescription =
      this.handleChangeEditedCategoryDescription.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.getParentCategoryName = this.getParentCategoryName.bind(this);
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    getWarehouseProductsCategories()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return [];
        }
      })
      .then((apiData) => {
        const formattedData = [
          {
            id: 0,
            name: "brak",
            description: "Brak opisu",
            parent: 0,
          },
          ...apiData.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
            parent: item.parent,
          })),
        ];
        this.setState({
          products_categories: formattedData,
          optionCategoryGroup: formattedData.map((categoryData) => ({
            value: categoryData.id,
            label: categoryData.name,
          })),
        });
      })
      .catch((error) => {
        console.error("Błąd podczas pobierania kategorii produktów:", error);
      });
  }

  getParentCategoryName(parentId) {
    const { products_categories } = this.state;
    if (parentId === 0) {
      return "Brak";
    } else {
      const parentCategory = products_categories.find(
        (category) => category.id === parentId,
      );
      return parentCategory ? parentCategory.name : "Brak";
    }
  }

  addCategory() {
    const { newCategoryName, newCategoryParentId, newCategoryDescription } =
      this.state;
    const dataBody = {
      name: newCategoryName,
      parent: newCategoryParentId,
      description: newCategoryDescription,
    };

    const addCategoryAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    addCategoryAlert
      .fire({
        title: "Sukces",
        text: "Pomyślnie dodano kategorię.",
        icon: "success",
        confirmButtonText: "Ok",
      })
      .then((result) => {
        this.toggleAddCategory();
        if (result.isConfirmed) {
          postWarehouseProductsCategories(dataBody)
            .then((response) => {
              if (response.ok) {
                this.getCategories();
              } else {
                // Obsłuż błąd
                Swal.fire(
                  "Błąd",
                  "Wystąpił problem podczas dodawania kategorii",
                  "error",
                );
              }
            })
            .catch((error) => {
              console.error("Błąd podczas dodawania kategorii:", error);
              // Obsłuż błąd
              Swal.fire(
                "Błąd",
                "Wystąpił problem podczas dodawania kategorii",
                "error",
              );
            });
        }
      });
  }

  editCategory(category_id) {
    getWarehouseProductsCategory(category_id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Nie można pobrać kategorii");
        }
      })
      .then((categoryData) => {
        this.setState({
          editedInputValue: {
            name: categoryData.name,
            parent: categoryData.parent,
            description: categoryData.description,
          },
          selectedCategoryId: category_id,
          modal_editCategory: true,
        });
      })
      .catch((error) => {
        console.error("Błąd podczas pobierania kategorii:", error);
        Swal.fire(
          "Błąd",
          "Wystąpił problem podczas pobierania kategorii",
          "error",
        );
      });
  }

  saveEditedCategory() {
    const { editedInputValue, selectedCategoryId } = this.state;
    const dataBody = {
      name: editedInputValue.name,
      parent: editedInputValue.parent,
      description: editedInputValue.description,
    };

    updateWarehouseProductsCategories(selectedCategoryId, dataBody)
      .then((response) => {
        if (response.ok) {
          this.toggleEditCategory();
          this.getCategories();
          Swal.fire("Sukces", "Pomyślnie zaktualizowano kategorię", "success");
        } else {
          throw new Error("Nie można zaktualizować kategorii");
        }
      })
      .catch((error) => {
        console.error("Błąd podczas edycji kategorii:", error);
        Swal.fire("Błąd", "Wystąpił problem podczas edycji kategorii", "error");
      });
  }

  // deleteCategory(category_id) {
  //     const deleteCategoryAlert = Swal.mixin({
  //         customClass: {
  //             confirmButton: "btn btn-success",
  //             cancelButton: "btn btn-danger",
  //         },
  //         buttonsStyling: false,
  //     });
  //     deleteCategoryAlert
  //         .fire({
  //             title: "Czy na pewno chcesz usunąć tę kategorię?",
  //             text: "Tej operacji nie można cofnąć!",
  //             icon: "warning",
  //             showCancelButton: true,
  //             confirmButtonText: "Tak, usuń",
  //             cancelButtonText: "Anuluj",
  //             reverseButtons: true,
  //         })
  //         .then((result) => {
  //             if (result.isConfirmed) {
  //                 deleteWarehouseProductsCategories(category_id)
  //                     .then((response) => {
  //                         if (response.ok) {
  //                             this.getCategories();
  //                             Swal.fire('Sukces', 'Pomyślnie usunięto kategorię', 'success');
  //                         } else {
  //                             throw new Error('Nie można usunąć kategorii');
  //                         }
  //                     })
  //                     .catch((error) => {
  //                         console.error('Błąd podczas usuwania kategorii:', error);
  //                         Swal.fire('Błąd', 'Wystąpił problem podczas usuwania kategorii', 'error');
  //                     });
  //             }
  //         });
  // }

  deleteCategory(category_id) {
    const deleteCategoryAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    deleteCategoryAlert
      .fire({
        title: "Czy na pewno chcesz usunąć tę kategorię?",
        text: "Tej operacji nie można cofnąć!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteWarehouseProductsCategories(category_id)
            .then((response) => {
              if (response.ok) {
                this.getCategories();
                // Zamknij okno edycji
                this.setState({ modal_editCategory: false });
                Swal.fire("Sukces", "Pomyślnie usunięto kategorię", "success");
              } else {
                throw new Error("Nie można usunąć kategorii");
              }
            })
            .catch((error) => {
              console.error("Błąd podczas usuwania kategorii:", error);
              Swal.fire(
                "Błąd",
                "Wystąpił problem podczas usuwania kategorii",
                "error",
              );
            });
        }
      });
  }

  toggleAddCategory() {
    this.setState((prevState) => ({
      modal_addCategory: !prevState.modal_addCategory,
    }));
  }

  toggleEditCategory() {
    this.setState((prevState) => ({
      modal_editCategory: !prevState.modal_editCategory,
    }));
  }

  handleChangeNewCategoryName(event) {
    this.setState({ newCategoryName: event.target.value });
  }

  handleChangeNewCategoryParentId(selectedOption) {
    this.setState({
      newCategoryParentId: selectedOption ? selectedOption.value : null,
    });
  }

  handleChangeNewCategoryDescription(event) {
    this.setState({ newCategoryDescription: event.target.value });
  }

  handleChangeEditedCategoryName(event) {
    const { editedInputValue } = this.state;
    this.setState({
      editedInputValue: {
        ...editedInputValue,
        name: event.target.value,
      },
    });
  }

  handleChangeEditedCategoryParentId(selectedOption) {
    const { editedInputValue } = this.state;
    this.setState({
      editedInputValue: {
        ...editedInputValue,
        parent: selectedOption ? selectedOption.value : null,
      },
    });
  }

  handleChangeEditedCategoryDescription(event) {
    const { editedInputValue } = this.state;
    this.setState({
      editedInputValue: {
        ...editedInputValue,
        description: event.target.value,
      },
    });
  }

  handleSelectCategoryGroup(selectedCategoryGroup) {
    this.setState({ selectedCategoryGroup });
  }

  render() {
    const {
      products_categories,
      optionCategoryGroup,
      modal_addCategory,
      modal_editCategory,
      editedInputValue,
    } = this.state;

    let dataValue = {
      columns: [
        {
          label: "Id",
          field: "category_id",
          sort: "asc",
          width: 10,
        },
        {
          label: "Nazwa",
          field: "category_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Opis",
          field: "category_description",
          sort: "asc",
          width: 250,
        },
        {
          label: "Kategoria nadrzędna",
          field: "parent_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Edytuj",
          sort: "disable",
          field: "edit",
          sortIconBefore: false,
        },
      ],
      rows: products_categories
        .filter((data) => data.id !== 0)
        .map((data) => ({
          category_name: data.name,
          category_description: data.description,
          category_id: data.id,
          parent_name:
            optionCategoryGroup.find((option) => option.value === data.parent)
              ?.label || "Brak",
          edit: (
            <MDBBtn
              onClick={() => {
                this.editCategory(data.id);
              }}
              position="center"
              color="info"
              size="sm"
            >
              <i className="ri-edit-2-fill" />
            </MDBBtn>
          ),
        })),
    };

    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                          Kategorie produktów
                        </h2>
                        <div className="flex-buttons">
                          <Button
                            data-toggle="modal"
                            data-target="#modalPostCategory"
                            type="button"
                            onClick={this.toggleAddCategory}
                            color="primary"
                            className="waves-effect waves-light"
                          >
                            Dodaj nową kategorię
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody className="custom-data-table">
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={dataValue}
                      {...translations.datatable}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="category__modal"
                isOpen={modal_addCategory}
                toggle={this.toggleAddCategory}
              >
                <ModalHeader toggle={this.toggleAddCategory}>
                  Dodaj kategorię
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={this.addCategory} id="addCategoryForm">
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            id="newCategoryName"
                            className="addCategoryField"
                            name="newCategoryName"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę"
                            type="text"
                            errorMessage="Wprowadź nazwę"
                            validate={{ required: true }}
                            onChange={this.handleChangeNewCategoryName}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormGroup className="select5-container">
                            <Label>Kategoria nadrzędna</Label>
                            <Select
                              defaultValue={optionCategoryGroup[0]}
                              onChange={this.handleChangeNewCategoryParentId}
                              options={optionCategoryGroup}
                              classNamePrefix="select5-selection"
                              isSearchable={false}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <AvField
                            id="newCategoryDescription"
                            className="addCategoryField"
                            name="newCategoryDescription"
                            label="Opis"
                            placeholder="Wprowadź opis"
                            type="textarea"
                            errorMessage="Wprowadź opis"
                            validate={{ required: true }}
                            onChange={this.handleChangeNewCategoryDescription}
                          />
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                Dodaj
                              </Button>{" "}
                              <Button
                                onClick={this.toggleAddCategory}
                                type="reset"
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>

        <Modal
          contentClassName="category__modal"
          isOpen={modal_editCategory}
          toggle={this.toggleEditCategory}
        >
          <ModalHeader toggle={this.toggleEditCategory}>
            Edytuj kategorię
          </ModalHeader>
          <ModalBody>
            <AvForm
              onValidSubmit={this.saveEditedCategory}
              id="editCategoryForm"
            >
              <Container>
                <Row>
                  <Col lg={6}>
                    <AvField
                      id="editedCategoryName"
                      className="addCategoryField"
                      name="editedCategoryName"
                      label="Nazwa"
                      placeholder="Wprowadź nazwę"
                      type="text"
                      errorMessage="Wprowadź nazwę"
                      validate={{ required: true }}
                      value={editedInputValue.name}
                      onChange={this.handleChangeEditedCategoryName}
                    />
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="select5-container">
                      <Label>Kategoria nadrzędna</Label>
                      <Select
                        defaultValue={optionCategoryGroup.find(
                          (option) => option.value === editedInputValue.parent,
                        )}
                        onChange={this.handleChangeEditedCategoryParentId}
                        options={optionCategoryGroup}
                        classNamePrefix="select5-selection"
                        isSearchable={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <AvField
                      id="editedCategoryDescription"
                      className="addCategoryField"
                      name="editedCategoryDescription"
                      label="Opis"
                      placeholder="Wprowadź opis"
                      type="textarea"
                      errorMessage="Wprowadź opis"
                      validate={{ required: true }}
                      value={editedInputValue.description}
                      onChange={this.handleChangeEditedCategoryDescription}
                    />
                  </Col>
                  <Col lg={12}>
                    <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="primary" className="mr-1">
                          Zapisz
                        </Button>{" "}
                        <Button
                          onClick={this.toggleEditCategory}
                          type="reset"
                          color="secondary"
                        >
                          Anuluj
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col
                    lg={12}
                    className="border-top mt-3"
                    style={{ borderColor: "#eff2f7" }}
                  >
                    <FormGroup className="mb-0 d-flex justify-content-end">
                      <div className="d-flex">
                        <Button
                          onClick={() => {
                            this.deleteCategory(this.state.selectedCategoryId);
                          }}
                          color="danger"
                          className="mt-3"
                        >
                          Usuń kategorię
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Container>
            </AvForm>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Categories;
