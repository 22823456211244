import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { checkLogin, apiError } from "../../store/actions";
import logoDark from "../../assets/images/logo-dark.png";
import logowhite from "../../assets/images/logo-light.png";
import * as getUsers from "../../Api/Users/getUsers";
import * as postOauth from "../../Api/Oauth/postOauth";
import { SalonSelect } from "../../components/SalonSelect/SalonSelect";
import { UserSalonsContext } from "../../providers/UserSalonsContext";
import ToastNotifications from "../../components/ToastNotification/ToastNotification";
import { CLIENT_ID, CLIENT_SECRET } from "../../utils/const";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "admin@themesdesign.in",
      password: "123456",
      data: null,
      user: [],
      isCustomerLogin: null,
      loginSucces: false,
      selectedSalon: "",
      userSalons: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserLogin = this.handleUserLogin.bind(this);
  }

  handleSubmit(event, values) {
    this.props.checkLogin(values, this.props.history);
  }

  handleUserLogin(setSalons) {
    const email = document.querySelector("#username");
    const password = document.querySelector("#password");
    let data = {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: email.value,
      password: password.value,
    };
    const dataBody = Object.assign({}, data);
    postOauth.postOauthToken(dataBody).then((response) => {
      if (response.status === 400) {
        return ToastNotifications(
          "error",
          "Niepoprawny login/hasło!",
          "Oops...",
        );
      }

      if (response.status === 200) {
        response.json().then((data) => {
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);

          // We can know if user is not disabled only after calling /me endpoint
          getUsers.getLoggedUserInfo().then((getLoggedUserInfoResponse) => {
            if (getLoggedUserInfoResponse.status === 200) {
              getLoggedUserInfoResponse.json().then((data2) => {
                ToastNotifications(
                  "success",
                  "Zalogowano pomyślnie.",
                  "Sukces",
                );
                const defaultCalendarData =
                  data2?.defaultCalendarView && data2?.defaultCalendarView > 0
                    ? data2?.defaultCalendarView - 1
                    : 3;
                localStorage.setItem(
                  "defaultCalendarView",
                  defaultCalendarData.toString(),
                );
                localStorage.setItem("user_salons", data2.salons);
                localStorage.setItem(
                  "user_salons_labels",
                  JSON.stringify(data2.salonLabels),
                );
                localStorage.setItem("first_name", data2.first_name);
                localStorage.setItem("last_name", data2.last_name);
                localStorage.setItem("username", data2.username);
                localStorage.setItem("user_id", data2.id);
                localStorage.setItem("avatar", data2.avatar);
                localStorage.setItem(
                  "is_owner",
                  data2.roles.indexOf("ROLE_OWNER") >= 0,
                );
                localStorage.setItem(
                  "is_sa",
                  data2.roles.indexOf("ROLE_SUPER_ADMIN") >= 0,
                );
                localStorage.setItem(
                  "is_employee",
                  data2.roles.indexOf("ROLE_EMPLOYEE") >= 0,
                );
                localStorage.setItem(
                  "is_customer",
                  data2.roles.indexOf("ROLE_CUSTOMER") >= 0,
                );
                this.setState({
                  userSalons: data2.salons,
                  loginSucces: true,
                });
                setSalons(data2.salons);
              });
            }

            if (getLoggedUserInfoResponse.status === 401) {
              localStorage.clear();
              return ToastNotifications(
                "error",
                "To konto nie jest aktywne, czy aktywowałeś link wysłany na dany email?",
                "Oops...",
              );
            }
          });
        });
      }
    });
  }

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white" />
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={5}>
                <div className="authentication-bg">
                  <Link to="/" className="logo ml-3 mt-2 authentication">
                    <img src={logowhite} height="40" alt="logo" />
                  </Link>
                  <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
                    <h1 className="display-4 text-white font-weight-normal title">
                      Zaloguj się do aplikacji
                    </h1>
                    <p className="text-white description">
                      Klienci oraz właściciele salonów mogą skorzystać z
                      świetnych funkcjonalności naszej aplikacji pzekonaj się
                      teraz!
                    </p>
                    <div className="action">
                      <a href="#">
                        <i className="fa fa-play" aria-hidden="true" />
                      </a>
                      <span>Jak działa aplikacja?</span>
                    </div>
                  </div>
                  <div className="bg-overlay" />
                </div>
              </Col>
              <Col lg={7}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        {this.state.loginSucces ? (
                          this.state.userSalons.length === 1 ? (
                            <div
                              onChange={localStorage.setItem(
                                "salon_name",
                                this.state.userSalons[0],
                              )}
                            >
                              {window.location.replace("/dashboard")}
                            </div>
                          ) : (
                            <div>
                              <div className="text-center">
                                <div>
                                  <Link to="/" className="logo">
                                    <img
                                      src={logoDark}
                                      height="20"
                                      alt="logo"
                                    />
                                  </Link>
                                </div>
                                <p className="text-muted">
                                  Aby przejść dalej wybierz salon do którego
                                  chcesz się zalogować
                                </p>
                              </div>

                              <SalonSelect
                                userSalons={this.state.userSalons}
                                formClassNames={"w-100"}
                                redirect={"/dashboard"}
                                withLabel
                              />
                            </div>
                          )
                        ) : (
                          <div>
                            <div className="text-center">
                              <div>
                                <Link to="/" className="logo">
                                  <img src={logoDark} height="20" alt="logo" />
                                </Link>
                              </div>

                              <h4 className="font-size-18 mt-4">Zaloguj się</h4>
                              <p className="text-muted">
                                Zacznij korzystać z aplikacji Visitio
                              </p>
                            </div>

                            {/* <Button color="primary">Zaloguj jako klient</Button> */}
                            {this.props.loginError && this.props.loginError ? (
                              <Alert color="danger">
                                {this.props.loginError}
                              </Alert>
                            ) : null}

                            <div className="p-2 mt-5">
                              {/* <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} > */}

                              <UserSalonsContext.Consumer>
                                {({ setSalons }) => (
                                  <AvForm
                                    id="userLoginForm"
                                    className="form-horizontal"
                                    onValidSubmit={() =>
                                      this.handleUserLogin(setSalons)
                                    }
                                  >
                                    <FormGroup className="auth-form-group-custom mb-4">
                                      <i className="ri-user-2-line auti-custom-input-icon" />
                                      <Label htmlFor="username">
                                        Adres Email
                                      </Label>
                                      <AvField
                                        name="username"
                                        type="text"
                                        className="form-control"
                                        errorMessage="Niepoprawny adres e-mail"
                                        id="username"
                                        validate={{ email: true }}
                                        placeholder="Wprowadź adres e-mail"
                                      />
                                    </FormGroup>

                                    <FormGroup className="auth-form-group-custom mb-4">
                                      <i className="ri-lock-2-line auti-custom-input-icon" />
                                      <Label htmlFor="userpassword">
                                        Hasło
                                      </Label>
                                      <AvField
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Wprowadź hasło"
                                      />
                                    </FormGroup>

                                    <div className="custom-control custom-checkbox">
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customControlInline"
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="customControlInline"
                                      >
                                        Zapamiętaj mnie
                                      </Label>
                                    </div>

                                    <div className="mt-4 text-center">
                                      <Button
                                        color="primary"
                                        className="w-md waves-effect waves-light fluid login-btn p-3"
                                        type="submit"
                                      >
                                        Zaloguj się
                                      </Button>
                                    </div>

                                    <div className="mt-4 text-center">
                                      <Link
                                        to="/forgot-password"
                                        className="text-muted"
                                      >
                                        <i className="mdi mdi-lock mr-1" />{" "}
                                        Zapomniałeś/aś hasło?
                                      </Link>
                                    </div>
                                  </AvForm>
                                )}
                              </UserSalonsContext.Consumer>
                            </div>

                            <div className="mt-5 text-center">
                              <p>
                                Nie masz jeszcze konta?{" "}
                                <Link
                                  to="/register"
                                  className="font-weight-medium text-primary"
                                >
                                  {" "}
                                  Zarejestruj się{" "}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStateToProps, { checkLogin, apiError })(Login),
);
